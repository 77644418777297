var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "slide-preview__content" }, [
    _c(
      "div",
      [
        _vm.croppedImage
          ? _c("swiper-slide", { staticClass: "swiper-slide" }, [
              _c("div", { staticClass: "swiper-slide__block" }, [
                _c(
                  "div",
                  {
                    staticClass: "swiper-slide__description",
                    class: {
                      "swiper-slide__description-align-right":
                        _vm.descriptionAlignRight,
                      "swiper-slide__description-align-bottom":
                        _vm.descriptionAlignBottom
                    }
                  },
                  [
                    _c("h3", { style: "color:" + _vm.titleColor }, [
                      _vm._v(_vm._s(_vm.title))
                    ]),
                    _vm._v(" "),
                    _c("p", { style: "color:" + _vm.subtitleColor }, [
                      _vm._v(_vm._s(_vm.subtitle))
                    ]),
                    _vm._v(" "),
                    _vm.buttonTitle
                      ? _c("a", { staticClass: "swiper-slide__button" }, [
                          _vm._v(_vm._s(_vm.buttonTitle))
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "swiper-slide__img" }, [
                  _c("img", { attrs: { src: _vm.croppedImage } })
                ])
              ])
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-365073fe", { render: render, staticRenderFns: staticRenderFns })
  }
}