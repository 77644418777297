import parse_str from 'locutus/php/strings/parse_str'
import http_build_query from 'locutus/php/url/http_build_query'

const getParams = (query) => {
    if (query.charAt(0) === '?') {
        query = query.substr(1)
    }

    let params = []
    parse_str(query, params)
    return params
}

const createQuery = (params) => `?${http_build_query(params)}`

const currentURL = new URL(document.URL)


export const getPriceFilter = () =>
    getParams(currentURL.search)['priceFilter']

export const getOptionsFilter = () => {
    const options = getParams(currentURL.search)['optionFilter']

    return Object.keys(options || {}).map(key => ({
        key: key.toLowerCase(),
        values: Object.values(options[key]).map(value => value.toLowerCase()),
    }))
}

export const setPriceFilter = ({ from, to }) => {
    const params = {
        ...getParams(currentURL.search),
        page: 1,
        priceFilter: { from, to },
    }

    window.location = `${currentURL.protocol}//${currentURL.host}${currentURL.pathname}${createQuery(params)}`
}

export const setOptionFilter = (options) => {
    const params = {
        ...getParams(currentURL.search),
        page: 1,
        optionFilter: options.reduce((optionsAcc, option) => {
            optionsAcc[option.key] = option['values'].reduce((valuesAcc, value, index) => {
                valuesAcc[index] = value
                return valuesAcc
            }, {})
            return optionsAcc
        }, {}),
    }

    window.location = `${currentURL.protocol}//${currentURL.host}${currentURL.pathname}${createQuery(params)}`
}

export const setFilters = ({ from, to }, options) => {
    const params = {
        ...getParams(currentURL.search),
        page: 1,
        priceFilter: { from, to },
        optionFilter: options.reduce((optionsAcc, option) => {
            optionsAcc[option.key] = option['values'].reduce((valuesAcc, value, index) => {
                valuesAcc[index] = value
                return valuesAcc
            }, {})
            return optionsAcc
        }, {}),
    }

    window.location = `${currentURL.protocol}//${currentURL.host}${currentURL.pathname}${createQuery(params)}`
}
