var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "image-cropper__content" }, [
      _c(
        "div",
        { staticClass: "image-cropper__cropper" },
        [
          _c("croppa", {
            ref: "cropper",
            staticClass: "image-cropper__croppa",
            attrs: {
              "auto-sizing": "",
              placeholder: "Перетащите изображение сюда, либо нажмите",
              "placeholder-color": "#000",
              "placeholder-font-size": 16,
              "canvas-color": "transparent",
              "show-remove-button": true,
              "remove-button-color": "black",
              "initial-image": _vm.croppedImageOrigin,
              "show-loading": true,
              "disable-scroll-to-zoom": false,
              "loading-size": 50
            },
            on: { "initial-image-loaded": _vm.setData }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "actions" }, [
        _c(
          "a",
          {
            attrs: { href: "#", title: "повернуть вправо", role: "button" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.rotate()
              }
            }
          },
          [_c("rotate-right-icon")],
          1
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: { href: "#", title: "повернуть влево", role: "button" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.rotate(-1)
              }
            }
          },
          [_c("rotate-left-icon")],
          1
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            ref: "flipX",
            attrs: {
              href: "#",
              title: "развернуть по горизонтали",
              role: "button"
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.flipX.apply(null, arguments)
              }
            }
          },
          [_c("flip-horizontal-icon")],
          1
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            ref: "flipY",
            attrs: {
              href: "#",
              title: "развернуть по вертикали",
              role: "button"
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.flipY.apply(null, arguments)
              }
            }
          },
          [_c("flip-vertical-icon")],
          1
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: { href: "#", title: "сбросить настройки", role: "button" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.reset.apply(null, arguments)
              }
            }
          },
          [_c("reload-icon")],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6968aa5c", { render: render, staticRenderFns: staticRenderFns })
  }
}