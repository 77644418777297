var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "init-modal",
    {
      staticClass: "data-removal-modal",
      attrs: { "is-show": _vm.isShow },
      on: { close: _vm.closeModal }
    },
    [
      _c("h2", [_vm._v("Запрос на удаление данных")]),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("app-input", {
            attrs: { label: "Имя" },
            model: {
              value: _vm.formData.name,
              callback: function($$v) {
                _vm.$set(_vm.formData, "name", $$v)
              },
              expression: "formData.name"
            }
          }),
          _vm._v(" "),
          _c("app-input", {
            attrs: { label: "Email" },
            model: {
              value: _vm.formData.email,
              callback: function($$v) {
                _vm.$set(_vm.formData, "email", $$v)
              },
              expression: "formData.email"
            }
          }),
          _vm._v(" "),
          _c("app-textarea", {
            attrs: { label: "Комментарий" },
            model: {
              value: _vm.formData.comment,
              callback: function($$v) {
                _vm.$set(_vm.formData, "comment", $$v)
              },
              expression: "formData.comment"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "data-removal-modal__btn" },
            [
              _c("app-button", { attrs: { type: "submit" } }, [
                _vm._v("Отправить запрос")
              ])
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0ab5cb80", { render: render, staticRenderFns: staticRenderFns })
  }
}