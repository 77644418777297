<template>
  <init-modal
      class="data-removal-modal"
      @close="closeModal"
      :is-show="isShow"
  >
    <h2>Запрос на удаление данных</h2>
    <form novalidate @submit.prevent>
      <app-input v-model="formData.name" label="Имя"></app-input>
      <app-input v-model="formData.email" label="Email"></app-input>
      <app-textarea v-model="formData.comment" label="Комментарий"></app-textarea>
      <div class="data-removal-modal__btn">
        <app-button type="submit">Отправить запрос</app-button>
      </div>
    </form>
  </init-modal>
</template>

<script>
import initModal from '../init-modal/initModal.vue'
import appButton from '../../app-button/appButton.vue'
import appInput from '@ui/app-input/appInput.vue'
import appTextarea from '@ui/app-textarea/appTextarea.vue'

export default {
  name: 'dataRemovalModal',
  components: { initModal, appButton, appInput, appTextarea },
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      formData: {
        name: '',
        email: '',
        comment: ''
      }
    };
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>
